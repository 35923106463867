import CalendarIcon from '../../../kit/icons/CalendarIcon';
import ClockIcon from '../../../kit/icons/ClockIcon';
import JSIcon from '../../../kit/icons/JSIcon';
import CodeIcon from '../../../kit/icons/CodeIcon';
import LocateIcon from '../../../kit/icons/LocateIcon';
import CardCheckIcon from '../../../kit/icons/CardCheckIcon';
import CardFreeIcon from '../../../kit/icons/CardFreeIcon';
import CardJSIcon from '../../../kit/icons/CardJSIcon';
import CardMessageIcon from '../../../kit/icons/CardMessageIcon';
import CardRubleIcon from '../../../kit/icons/CardRubleIcon';

const data = {
  seo: {
    title: 'Курсы по веб-разработке JavaScript в Москве | Эльбрус Буткемп',
    meta: [
      {
        property: null,
        name: 'description',
        content:
          'Интенсивные курсы JavaScript в Санкт-Петербурге от Эльбрус Буткемп. Получите качественное образование и станьте востребованным специалистом в сфере разработки веб-приложений. Запишитесь на курс прямо сейчас!',
      },
      { property: 'og:type', name: null, content: 'product' },
      {
        property: 'og:title',
        name: null,
        content:
          'Курсы по веб-разработке JavaScript в Москве | Эльбрус Буткемп',
      },
      {
        property: 'og:description',
        name: null,
        content:
          'Интенсивные курсы JavaScript в Санкт-Петербурге от Эльбрус Буткемп. Получите качественное образование и станьте востребованным специалистом в сфере разработки веб-приложений. Запишитесь на курс прямо сейчас!',
      },
      {
        property: 'og:image',
        name: null,
        content: 'https://elbrusboot.camp/images/campus/03.JPG',
      },
      {
        property: 'og:site_name',
        name: null,
        content: 'Эльбрус Буткемп',
      },
      { property: 'og:locale', name: null, content: 'ru_RU' },
      { property: 'fb:app_id', name: null, content: '581051622277135' },
      { property: 'twitter:card', name: null, content: 'summary_large_image' },
      {
        property: 'twitter:site',
        name: null,
        content: 'https://twitter.com/elbrus_bootcamp',
      },
      {
        property: 'twitter:image:src',
        name: null,
        content: 'https://elbrusboot.camp/images/campus/03.JPG',
      },
    ],
  },
  careerDescription: {
    title: 'Fullstack-разработчик',
    description: [
      'человек, который возлагает на себя ответственность за все этапы разработки веб-сервиса. Он принимает участие как в создании визуальной части интернет-ресурса, так и в реализации серверной. Создает с нуля веб-приложение — самостоятельно или при поддержке команды разработчиков. Full stack = Frontend + Backend.',
      'Frontend — это то, что вы видите на мониторе. Backend — это все что отвечает за логику «под капотом»',
    ],
  },
  tools: {
    title: {
      first: 'Инструменты',
      second: 'Вы изучите',
    },
    folder: 'js',
    list: [
      {
        image: 'express',
        isMobileHidden: false,
      },
      {
        image: 'nodejs',
        isMobileHidden: false,
      },
      {
        image: 'ts',
        isMobileHidden: false,
      },
      {
        image: 'postgresql',
        isMobileHidden: false,
      },
      {
        image: 'jest',
        isMobileHidden: false,
      },
      {
        image: 'react',
        isMobileHidden: false,
      },
      {
        image: 'githubcat',
        isMobileHidden: false,
      },
      {
        image: 'git',
        isMobileHidden: false,
      },
      {
        image: 'redux',
        isMobileHidden: false,
      },
      {
        image: 'docker',
        isMobileHidden: true,
      },
    ],
  },
  employmentDescription: {
    title: 'Трудоустройство',
    markedSubTitle:
      'Карьерные коучи помогают с резюме, консультируют по IT-рынку, делятся лайфхаками по трудоустройству и остаются на связи в течение года после выпуска.',
    statistic: {
      id: '1',
      value: '86%',
      description: 'студентов\nнаходят работу\nза 3 месяца.',
      link: {
        url: '/job-report2023',
        title: 'Исследование hh.ru',
      },
    },
  },
  projects: {
    title: 'Реальные проекты',
    subtitle: 'В Вашем портфолио',
    data: {
      description:
        'Проекты в Эльбрус Буткемп — не симуляция настоящей работы, а заказы от компаний-партнеров, которые приглашают лучших студентов для дальнейшего сотрудничества. В процессе обучения на курсах JavaScript в Москве вы разработаете 3 проекта, которые станут частью вашего профессионального портфолио:',
      points: [
        'Проект от заказчика',
        'Индивидуальный проект',
        'Выпускной проект',
      ],
      list: [
        {
          description: 'Вешатель — стриминговая платформа',
          preview: 'broadcaster',
          video: 'https://www.youtube.com/embed/4sS5eHUSUN4',
        },
        {
          description: 'RPG — рпг игра',
          preview: 'rpggame',
          video: 'https://www.youtube.com/embed/dpG-1d8K24Y',
        },
        {
          description:
            'Карьерный коуч — приложение для анализа необходимых навыков на основании вакансий на рынке',
          preview: 'careercoach',
          video: 'https://www.youtube.com/embed/SHtxIZFg5Pw',
        },
        {
          description: 'Морской бой — онлайн морской бой',
          preview: 'seabattle',
          video: 'https://www.youtube.com/embed/DG3QrSGJztc',
        },
      ],
    },
  },
  schedule: {
    title: 'Расписание дня',
    subtitle: 'Примерное',
    list: [
      {
        time: '9:00 − 9:15',
        topic: 'утренний\nстендап',
        period: null,
        image: 'standup',
      },
      {
        time: '9:15 − 11:00',
        topic: 'лекция',
        period: null,
        image: null,
      },
      {
        time: '11:00 − 12:30',
        topic: 'Практика соло, в парах или в группах',
        period: null,
        image: 'coding',
      },
      {
        time: '12:30 − 13:15',
        topic: 'английский разговорный   клуб',
        period: null,
        image: null,
      },
      {
        time: '13:15 − 14:00',
        topic: 'Обед',
        period: null,
        image: null,
      },
      {
        time: '14:00 − 19:00',
        topic: 'Решение задач, работа над проектами',
        period: null,
        image: 'practice',
      },
      {
        time: '19:00 − 20:00',
        topic: 'приглашенный спикер/ pizza\u00A0day',
        period: null,
        image: null,
      },
    ],
  },
  instruction: {
    title: 'Как попасть в Эльбрус Буткемп',
    subtitle: 'Инструкция',
    steps: [
      {
        icon: CardCheckIcon,
        title: 'Познакомьтесь с\u00A0Эльбрус Буткемп',
        image: 'meetElbrus',
        preSubTitle: 'Посетите День открытых дверей:',
        subTitles: [
          '[Ближайший — =eventDate=](=eventLink=)<!--rehype:ymSubmitEventType=upcomingEvent-->. Расскажем об обучении и почему оно эффективно',
          'Задайте интересующие вопросы выпускникам. Они поделятся своим опытом обучения и трудоустройства в IT-компанию',
        ],
        button: 'перейти к расписанию встреч',
        eventFilter: 'MEETING',
        buttonLink: '/events/',
        buttonMetric: 'mskJSSchedule',
        freeStatus: CardFreeIcon,
        progress:
          'Понял формат буткемпа и получил ответы на интересующие вопросы',
        progressVal: 20,
      },
      {
        icon: CardJSIcon,
        title: 'Подготовьтесь к\u00A0интервью',
        image: 'examPreparation',
        subTitles: [
          'Зарегистрируйтесь в [личном кабинете](https://edu.elbrusboot.camp/?)<!--rehype:utm=true&target=_blank--> Эльбрус Буткемп и начните изучать JavaScript по [фирменным материалам →]()<!--rehype:modal=true&ymSubmitEventType=mskJSMaterials&ymSubmitExtraEventType=lead&program=JS\u0020мск&leadAction=Подписка\u0020гайд&leadBlock=Как\u0020поступить&leadPage=JS\u0020Msk_Основная\u0020программа&eventName=Материалы\u0020для\u0020подготовки&tags=мск-->',
          'Посетите бесплатные Мастер-классы',
          'Решите контрольные задачи',
          'При необходимости запишитесь на подготовительную программу с ментором [“Погружение в JS” →](/js-intense/)<!--rehype:ymSubmitEventType=divetoJS-->',
        ],
        button: 'Посетить мастер-класс',
        eventFilter: 'MASTER_KLASS',
        buttonLink: '/events/',
        buttonMetric: 'mskJSMK',
        freeStatus: CardFreeIcon,
        progress:
          'Освоил основы JavaScript. Запрограммировал первые собственные функции',
        progressVal: 50,
      },
      {
        icon: CardMessageIcon,
        title: 'Пройдите техническое интервью',
        image: 'techInterview',
        subTitles: [
          'Выберите удобную дату техинтервью',
          'Решите 3-4 задачи от ментора по видеосвязи',
        ],
        button: 'Перейти в личный кабинет',
        eventFilter: null,
        buttonLink:
          'https://edu.elbrusboot.camp/?utm_source=elbrus&utm_medium=roadmap_jsmsk&utm_campaign=button',
        target: '_blank',
        buttonMetric: 'visitLK',
        freeStatus: CardFreeIcon,
        progress: 'Забронировал место в Эльбрус Буткемп',
        progressVal: 75,
      },
      {
        icon: CardRubleIcon,
        title: 'Выберите дату старта обучения и варианты оплаты',
        image: 'startCourse',
        subTitles: [
          'Определитесь с удобной датой начала обучения',
          'Подпишите договор',
          'Выберите удобный [способ оплаты](/msk-js/#price)<!--rehype:ymSubmitEventType=mskJSPaymentMethods-->: рассрочка или единовременный платеж',
        ],
        button: 'посмотреть потоки обучения',
        eventFilter: null,
        buttonLink: '/msk-js/#streams',
        buttonMetric: 'mskJSStreams',
        freeStatus: null,
        progress:
          'Приступил к освоению востребованной профессии веб-разработчика',
        progressVal: 100,
      },
    ],
  },
  questions: {
    message: 'Готовы ответить на ваши вопросы',
    actions: {
      first: {
        title: 'Оставить заявку',
      },
    },
    ymEvents: {
      consultationFormSubmit: 'mskJSBottomConsultation',
    },
    myTargetEvents: {
      consultationFormSubmit: 'MskJSBottomConsultation',
    },
  },
  greetings: {
    location: {
      type: 'offline',
      title: 'Москва',
    },
    duration: {
      number: '12',
      currency: 'недель',
    },
    type: 'основная программа',
    title: ['Веб-', 'разработчик'],
    features: [
      {
        icon: 'week',
        title: '12 недель',
      },
      {
        icon: 'time',
        title: '500 часов',
      },
    ],
    action: 'Получить консультацию',
    ymEvents: {
      signUpFormSubmit: 'mskJSTopSignUp',
      consultationFormSubmit: 'mskJSTopConsultation',
    },
    myTargetEvents: {
      signUpFormSubmit: 'MskJSTopSignUp',
      consultationFormSubmit: 'MskJSTopConsultation',
    },
  },
  payment: {
    program: 'Fullstack Javascript',
    programType: 'Основная программа',
    title: 'Стоимость обучения',
    subtitle: 'Fullstack Javascript',
    data: {
      description: [
        {
          content: 'Окупаемость обучения уже на 3-й месяц.',
          link: {
            text: 'Данные',
            title: 'исследования hh.ru',
            url: '/job-report2023/',
          },
          icon: 'wallet',
        },
        {
          content:
            'Главная цель — трудоустройство. 86% студентов находят работу в течение 3 месяцев',
          icon: 'star',
        },
      ],
    },
    ymEvents: {
      paymentFormSubmitCredit: 'mskJSCredit',
      paymentFormSubmitFullPrice: 'mskJSPriceFull',
    },
    myTargetEvents: {
      paymentFormSubmitCredit: 'MskJSCredit',
      paymentFormSubmitFullPrice: 'mskJSPriceFull',
    },
  },
  education: {
    title: 'В кампусе с преподавателем',
    subtitle: 'Обучение',
    data: {
      list: [
        'Слушаете живые лекции в классных аудиториях и\u00A0получаете обратную связь от преподавателя',
        'Проводите code-review с ментором',
        'Работаете над проектами от заказчика',
        'После обучения готовитесь к собеседованиям вместе с карьерным коучем',
      ],
    },
  },
  details: {
    title: 'Главное',
    subtitle: 'Детали курса',
    details: [
      {
        title: 'График',
        info: '9 часов в день по будням',

        Icon: CalendarIcon,
      },
      { title: 'Часы занятий', info: '9:00 - 19:00', Icon: ClockIcon },
      {
        title: 'Профессия после обучения',
        info: 'Fullstack-разработчик\nна JavaScript и TypeScript',
        Icon: JSIcon,
      },
      {
        title: 'Адрес кампуса',
        info: 'ул.Орджоникидзе, д. 11 стр. 10 (м. Ленинский проспект)',
        Icon: LocateIcon,
      },
    ],
  },
  teachers: {
    subtitle: 'наши',
    title: 'Преподаватели',
  },
  groups: {
    title: 'Потоки обучения',
    subtitle: 'актуальные',
    program: 'Fullstack Javascript',
    type: 'основная программа',
    ymEvents: [
      {
        groupsFormOpen: 'mskJSFirstCardFormOpen',
        groupsFormSubmit: 'mskJSFirstCardFormSent',
      },
      {
        groupsFormOpen: 'mskJSSecondCardFormOpen',
        groupsFormSubmit: 'mskJSSecondCardFormSent',
      },
    ],
    myTargetEvents: [
      {
        groupsFormSubmit: 'MskJSFirstCardFormSent',
      },
      {
        groupsFormSubmit: 'MskJSSecondCardFormSent',
      },
    ],
  },
  structure: {
    title: ['Программа обучения', ' JavaScript в Москве'],
    subtitle: 'Подробная',
    minHeight: '193px',
    list: [
      {
        period: '1-3 неделя',
        phase: 'фаза 0',
        description:
          'Терминал,\u00A0Git,\u00A0основы\u00A0HTML/ CSS, основы JavaScript. Проходит online, 6-8 часов в день.',
        backgroundColor: 'kit.background.gray',
        marginTop: '0',
        minHeight: '138px',
      },
      {
        period: '4-6 неделя',
        phase: 'фаза 1',
        description:
          'Ванильный JavaScript, тестирование, структуры данных, алгоритмы.',
        backgroundColor: 'kit.background.lightpurple',
        marginTop: '-178px',
        minHeight: '138px',
      },
      {
        period: '7-9 неделя',
        phase: 'фаза 2',
        description:
          'Backend (Node.js, Express), базы данных. Два проекта для портфолио. Полноценная веб-разработка с клиент-серверной архитектурой. Обучение деплоименту на платформе Selectel.',
        backgroundColor: 'kit.background.darkgray',
        marginTop: '-178px',
        minHeight: '172px',
      },
      {
        period: '10-12 неделя',
        phase: 'фаза 3',
        description:
          'Frontend библиотеки (React, Redux). Связывание UI с back-end и базы данных PostgreSQL. Финальный проект.',
        backgroundColor: 'kit.background.lightpurple',
        marginTop: '-146px',
        minHeight: '162px',
      },
      {
        period: '5 дней',
        phase: 'Карьерная неделя',
        noTitleImg: true,
        description:
          'Стратегия по поиску работы, составление резюме, подготовка к собеседованиям.',
        backgroundColor: 'kit.background.middlepurple',
        marginTop: '-154px',
        minHeight: '140px',
      },
    ],
    ymEvents: {
      programFormSubmit: 'mskJSProgramm',
    },
    myTargetEvents: {
      programFormSubmit: 'MskJSProgram',
    },
  },
  projectsAndReviews: {
    projects: {
      title: 'Реальные проекты',
      image: 'reviews/projectsTitle',
      ymEvent: 'mskJSProjects',
    },
    reviews: {
      title: 'Отзывы',
      image: 'reviews/reviewsTitle',
      ymEvent: 'mskJSComments',
    },
  },
  graduates: {
    data: {
      people: [
        {
          photo: 'krisanov',
          fullName: 'Владислав Крисанов',
          content:
            'В 2022 году благодаря Эльбрусу я переквалифицировался из инженера-проектировщика во frontend-разработчика. Больше всего запомнилось чувство непонимания, сопровождающее меня каждую фазу, и сменяющее его чувство озарения, которое проявлялось перед каждым экзаменом.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/Slavvladi',
            },
          ],
        },
        {
          photo: 'zaitseva',
          fullName: 'Мария Зайцева',
          content:
            'Совокупность потока информации и внеучебной деятельности давали мне обалденную мотивацию. Теперь я получаю деньги за свои знания и путешествую по миру — такой мобильности у меня бы не было, если бы не новая профессия. Работаю fullstack разработчиком в Tentoora.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/mazay_tseva',
            },
          ],
        },
        {
          photo: 'kaminskiy',
          fullName: 'Евгений Каминский',
          content:
            'Я был manual QA, но хотел изменить специальность. Выпустился из буткемпа в Москве и через неделю получил оффер. При самостоятельном обучении я бы, возможно, и получил подобные знания, но затратил больше времени и сил. Помните, что усилия окупятся — вы будете на гребне волны в современных технологиях.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/EvgeniyCom',
            },
          ],
        },
      ],
    },
  },
  howDoesBootcampWork: {
    image: 'howBootcampWorks/500codingHours',
    pointsLists: [
      {
        title: 'Из чего состоит обучение',
        list: [
          'Живые лекции и обратная связь от преподавателя',
          'Код-ревью: проверка кода преподавателем',
          'Работа над проектами от заказчика',
          'Подготовка к собеседованиям вместе с карьерным коучем',
        ],
      },
      {
        title: 'Что получает выпускник',
        list: [
          'Профессия: разработчик JavaScript, TypeScript',
          'Портфолио на GitHub: 2 групповых, 1 индивидуальный и 10 учебных проектов',
          'Диплом о профессиональной переподготовке установленного образца',
          'Доступ к закрытому комьюнити разработчиков',
        ],
      },
    ],
  },
  examPrepare: {
    title: 'Как подготовиться к интервью?',
    preCourseTitle: 'Основы JavaScript•Онлайн',
    selfPrepareTitle: 'JavaScript с 0•Тренажер',
    description:
      'Подготовка к вступительному к интервью занимает от 2‑х до 4‑х недель. Вы можете подготовиться самостоятельно или с ментором в группе.',
    preCourse: {
      duration: '2 недели',
      hours: '20 часов',
      pointsList: [
        '10 живых занятий с ментором',
        'Индивидуальная обратная связь по задачам от ментора',
        'Поддержка в закрытом чате разработчиков',
      ],
      link: {
        url: '/javascript-basics/',
        title: 'Подробнее',
      },
      ymEvent: 'mskJSPreCourses',
      myTargetEvent: 'MskJSPreCourses',
    },
    trainer: {
      headerText: 'Бесплатный курс',
      pointsList: [
        'Изучите базовый синтаксис',
        'Попрактикуетесь в решении задач',
        'Получите обратную связь по вашим знаниям',
      ],
      ymEvent: 'mskJSTrainer',
      myTargetEvent: 'MskJSTrainer',
    },
  },
  atmosphere: {
    title: 'Атмосфера в кампусе',
    description:
      'В кампусе есть все как для учебы и регулярной практики, так и для отдыха между занятиями: настольные игры, пинг-понг, кикер, диваны, ланч-рум. А по пятницам — пицца!',
    slides: [
      {
        image: 'msk-slide-1',
      },
      {
        image: 'msk-slide-2',
      },
      {
        image: 'msk-slide-3',
      },
      {
        image: 'msk-slide-4',
      },
      {
        image: 'msk-slide-5',
      },
      {
        image: 'msk-slide-6',
      },
      {
        image: 'msk-slide-7',
      },
      {
        image: 'msk-slide-8',
      },
      {
        image: 'msk-slide-9',
      },
      {
        image: 'msk-slide-10',
      },
      {
        image: 'msk-slide-11',
      },
      {
        image: 'msk-slide-12',
      },
      {
        image: 'msk-slide-13',
      },
      {
        image: 'msk-slide-14',
      },
      {
        image: 'msk-slide-15',
      },
      {
        image: 'msk-slide-16',
      },
    ],
  },
  entranceInterview: {
    title: 'Вступительное интервью в Эльбрус Буткемп',
    video: 'https://www.youtube.com/embed/7JRBhVAHrYM',
    list: [
      'Перед поступлением будущих программистов ждет техническое интервью. Это нужно, чтобы студенты поняли, с чем им предстоит работать в ближайшие месяцы и убедились в серьезности своих намерений.',
      'Формат: ответы на вопросы и решение задач на видеозвонке, 45 минут',
    ],
    ymEvent: 'mskJSVideoInterview',
    myTargetEvent: 'MskJSVideoInterview',
  },
};

export default data;
